import React, { Component } from 'react'

class UppdragsgivareTemplate extends Component {
  state = {}
  render() {
    return (
      <React.Fragment>
        <p>yo</p>
      </React.Fragment>
    )
  }
}

export default UppdragsgivareTemplate
